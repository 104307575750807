
import {
  defineComponent, ref
} from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmPhoneSelect from '@/components/shared/TmPhoneSelect.vue'
import { useModes } from '@/compositions/modes'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  components: {
    TmEmptyState,
    FlowStep,
    TmFormLine,
    TmButton,
    TmTooltip,
    TmPhoneSelect,
  },
  setup() {
    const { openAutomationRuleAddKeyword } = useAutomationRuleOpenModal('keyword')
    const { isEmptyMode } = useModes()

    const account = ref(reportingCategories[1])

    const ruleName = ref('')

    const lists = ref([])
    const listsOptions = ref(['JOIN', 'KEYWORD', 'MESSAGE', 'KEYWORD2', 'MESSAGE2'])

    const triggerValue = ref('In the beginning of the text')

    const numbersOptions = ref([
      '(603) 555-0121',
      '(603) 555-0122',
      '(603) 555-0123',
      '(603) 555-0124',
      '(603) 555-0125',
    ])
    const numbers = ref([])

    const countries = ref([])

    return {
      account,
      ruleName,
      lists,
      listsOptions,
      triggerValue,
      numbersOptions,
      numbers,
      countries,
      isEmptyMode,
      openAutomationRuleAddKeyword,
    }
  },
})

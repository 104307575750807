
import {
  defineComponent, ref
} from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import AutomationRulesStep1 from '@/components/pages/services/automationRules/flow/AutomationRulesStep1.vue'
import AutomationRulesStep2 from '@/components/pages/services/automationRules/flow/AutomationRulesStep2.vue'
import AutomationRulesStep3 from '@/components/pages/services/automationRules/flow/AutomationRulesStep3.vue'
import AutomationRulesStep4 from '@/components/pages/services/automationRules/flow/AutomationRulesStep4.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    PageBlock,
    DetailsHero,
    TmButton,
    FlowStepList,
    AutomationRulesStep1,
    AutomationRulesStep2,
    AutomationRulesStep3,
    AutomationRulesStep4,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Automation rules', name: 'base.services.automationRules.rules' },
      { label: 'Create a new rule' },
    ]
    const { isMdMax } = useBreakpoints()

    const currentStep = ref(1)

    return {
      breadcrumbs,
      currentStep,
      isMdMax,
    }
  },
})


import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import FinishBlock from '@/components/shared/templates/FinishBlock.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountriesRow from '@/components/shared/TmCountriesRow.vue'
import ResultTable from '@/components/shared/templates/result/ResultTable.vue'
import type { ResultTableItemType } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    FlowStep,
    FinishBlock,
    TmBadge,
    TmBadges,
    TmButton,
    TmCountriesRow,
    ResultTable,
  },
  setup() {
    const countries = ref([
      {
        id: 'us',
        name: 'United States',
      },
      {
        id: 'ca',
        name: 'Canada',
      },
    ])

    const tableValue: ResultTableItemType[] = [
      {
        label: 'Rule ID',
        value: '17242',
      },
      {
        label: 'Account',
        value: 'John Doe – john.doe@example.com',
      },
      {
        label: 'Affected numbers',
        value: '+37259154736',
      },
      {
        label: 'Affected countries',
        value: '',
      },
      {
        label: 'Affected keywords',
        value: '',
      },
      {
        label: 'Auto responder type',
        value: 'Text message',
      },
      {
        label: 'Auto responder text',
        value: '',
        labelTop: true,
      },
      {
        label: 'Additional action 1',
        value: '',
      },
    ]

    return {
      countries,
      tableValue,
    }
  },
})


import {
  defineComponent, ref
} from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmWeekSchedule from '@/components/shared/TmWeekSchedule.vue'
import { weekScheduleDefault } from '@/definitions/_general/_data/general'

type AutomationAction = {
  type: string
  list: { label: string }
}

export default defineComponent({
  components: {
    FlowStep,
    TmFormLine,
    TmButton,
    TmWeekSchedule,
  },
  setup() {
    const actions = ref<AutomationAction[]>([])

    const actionTypeOptions = ref([
      'Add contact to list',
      'Remove contact from list',
      'Remove contact from all my lists',
    ])

    const actionListOptions = ref([
      { label: 'Customers' },
      { label: 'Partners' },
      { label: 'Team 1' },
      { label: 'Team 2' },
    ])

    const addNewAction = () => {
      actions.value.push({
        type: 'Add contact to list',
        list: { label: 'Customers' },
      })
    }

    const deleteAction = (i: number) => {
      actions.value.splice(i, 1)
    }

    const ruleSchedule = ref('Always run')

    const weekSchedule = ref(weekScheduleDefault)

    return {
      addNewAction,
      deleteAction,
      actions,
      actionTypeOptions,
      actionListOptions,
      ruleSchedule,
      weekSchedule,
    }
  },
})

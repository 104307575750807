
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import ComposeFormLine from '@/components/shared/compose/ComposeFormLine.vue'
import MessageEditor from '@/components/shared/compose/MessageEditor.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    MessageEditor,
    ComposeFormLine,
    FlowStep,
    TmFormLine,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const autoResponseType = ref('Text message')

    const contentMessage = 'Hi {First name}\n\nI just sent your Resume to your email.\nPlease REPLY back to me to CONFIRM you received your Resume OK.'

    const triggerRule = ref(true)

    return {
      autoResponseType,
      contentMessage,
      triggerRule,
      isSmMax,
    }
  },
})

import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-195d9460"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "result-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_result_table_item = _resolveComponent("result-table-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_result_table_item, {
          key: _ctx.kebabCase(item.label),
          label: item.label,
          value: item.value,
          "label-top": item.labelTop
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, item.slot || _ctx.kebabCase(item.label), _normalizeProps(_guardReactiveProps(item)), () => [
              _createTextVNode(_toDisplayString(item.value), 1)
            ], true)
          ]),
          _: 2
        }, 1032, ["label", "value", "label-top"]))
      }), 128))
    ], true)
  ]))
}
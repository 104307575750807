
import type { PropType } from 'vue'
import {
  defineComponent, toRefs, ref, watch, computed
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

const numbersMapsVariant = ['option', 'select'] as const
type NumbersMapsVariant = typeof numbersMapsVariant[number]

type NumbersMaps = {
  [key in NumbersMapsVariant]: string[]
}

type NumbersChecked = {
  [key in NumbersMapsVariant]: string
}

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
  },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
    numbersOptions: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { isSmMax } = useBreakpoints()
    const { modelValue, numbersOptions } = toRefs(props)

    const numbersMaps = computed<NumbersMaps>(() => ({
      option: numbersOptions.value.filter((el) => !modelValue.value.includes(el)),
      select: modelValue.value,
    }))

    const checkedMaps = ref<NumbersChecked>({
      option: '',
      select: '',
    })

    watch(modelValue, () => {
      checkedMaps.value = {
        option: '',
        select: '',
      }
    })

    const clickOne = (type: NumbersMapsVariant) => {
      if (checkedMaps.value[type]) {
        emit(
          'update:modelValue',
          (type === 'option')
            ? modelValue.value.concat(checkedMaps.value[type])
            : modelValue.value.filter((n) => n !== checkedMaps.value[type])
        )
      }
    }

    const clickAll = (type: NumbersMapsVariant) => {
      if (numbersMaps.value[type].length) {
        emit(
          'update:modelValue',
          (type === 'option')
            ? modelValue.value.concat(numbersMaps.value[type])
            : []
        )
      }
    }

    return {
      numbersMapsVariant,
      numbersMaps,
      checkedMaps,
      clickOne,
      clickAll,
      isSmMax,
    }
  },
})


import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import ResultTableItem from './ResultTableItem.vue'
import kebabCase from 'lodash/kebabCase'
import type { ResultTableItemType } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    ResultTableItem,
  },
  props: {
    items: {
      type: Array as PropType<ResultTableItemType[]>,
    },
  },
  setup() {
    return {
      kebabCase,
    }
  },
})


import {
  defineComponent
} from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    labelTop: {
      type: Boolean,
    },
    value: {
      type: String,
    },
  },
})

import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d057e31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "result-table-item" }
const _hoisted_2 = { class: "result-table-item__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["result-table-item__label", {
        'self-start': _ctx.labelTop
      }])
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ], true)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.value), 1)
      ], true)
    ])
  ]))
}